import React, { Component } from "react";
import { Row, Col, Container, Table } from 'react-bootstrap';
import axios from 'axios';
import configData from "../../configs/ip.json";
import ReactApexChart from "react-apexcharts";

export default class Intersection extends Component {
    constructor(props) {
        super(props);

        this.state = {
            series: [],
            options: {
                chart: {
                    height: 350,
                    with:350,
                    type: 'heatmap',
                },
                dataLabels: {
                    enabled: true
                },
                colors: [
                    "#FF004D",
                    // Add more colors if needed
                ],
                
                xaxis: {
                    categories: [], // Set categories as source names for x-axis
                    title: {
                        text: 'Source Name' // Set x-axis title
                    }
                },
                yaxis: {
                    categories: [], // Set categories as source names for y-axis
                    title: {
                        text: 'Source Name' // Set y-axis title
                    }
                },
            },
        };
    }

    jaccardSimilarity(setA, setB) {
        const intersection = new Set([...setA].filter(x => setB.has(x)));
        const union = new Set([...setA, ...setB]);
    
        const similarity = intersection.size / union.size;
        return similarity;
    }

    async componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const type = urlParams.get('type');


        const rawSourcesData = await fetch(`${configData.clientIP}:${configData.serverPort}/statistics/abuses/All`)
        const data = await rawSourcesData.json()
        
        const labels = Object.keys(data);
        let series = [];

        labels.forEach(x_key => {
            let tempArray = labels.map(y_key => {
                const x_value = new Set(data[x_key]);
                const y_value = new Set(data[y_key]);
                const similarity = Math.ceil(this.jaccardSimilarity(x_value, y_value)*100)
                return similarity;
            });

            series.push({
                name: x_key,
                data: tempArray,
            });
        });

        this.setState({
            series: series,
            options: {
                ...this.state.options,
                xaxis: {
                    ...this.state.options.xaxis,
                    categories: labels,
                },
                yaxis: {
                    ...this.state.options.yaxis,
                    categories: labels,
                },
            },
        });
    }

    render() {
        return (
            <div>
                <h3>Intersection of sources</h3>
                <ReactApexChart options={this.state.options} series={this.state.series} type="heatmap" height={500} />
            </div>
        );
    }
}
