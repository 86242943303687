import '../../App.css';

import React, { Component } from "react";

import { Chart, ArcElement,  CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler} from 'chart.js';

import { Bar} from 'react-chartjs-2'

import colors from "./colors.json"

import configData from "../../configs/ip.json"
  


export default class AbuseTypeChart extends Component {


    async componentDidMount(){
      

      const headers = {
        "period":"anual"
      };

      const rawStatisticsData = await fetch(`${configData.clientIP}:${configData.serverPort}/statistics/bitcoin/getAllStatistics/`, { headers })
      const statisticsData = await rawStatisticsData.json()
      

      const rawStatisticsLabelsData = await fetch(`${configData.clientIP}:${configData.serverPort}/statistics/bitcoin/getLabels/`, { headers })
      const statisticsLabelsData = await rawStatisticsLabelsData.json()


      let state_data = {
        
        labels: Object.values(statisticsLabelsData) ,
        datasets: []
      }
      
      for (const [key, value] of Object.entries(statisticsData)) {
        
        state_data.datasets.push({
          label: key,
          
          data: value["recv"].map(value => (value / 1000000).toFixed(3)),
          borderColor:colors[key],
          backgroundColor: colors[key],
          
   
        })
      }
      
      this.setState({data: state_data})

    }

    constructor(props) {
        super(props)
        
        this.state ={

            propsData:[],
            
            total:0,
            num_of_addresses:0,
            number_of_txs:0,
            per: "anual",
            lastWhat:"forever",
            shownAs:"Millions",

            type:"",

            database:{},
            
            data: {
                labels: [],
                datasets: []
            },
            options : {
  
              
              plugins: {
                legend: {
                  position: 'bottom', // set position of legend labels
                },
                title: {
                  display: true,
                  // text: (ctx) => 'Chart.js Line Chart - stacked=' + ctx.chart.options.scales.y.stacked
                },
              },
              scales: {
                x: {
                  stacked: true,
                  display: true,
                },
                y: {
                  display: true,
                  stacked: true,
            
                  min: 0,
                }
              },
            
            }
        }


    
    }

   
    render(){
      
      
        return(
            <div >
                {/* <h1>hello</h1> */}
                <Bar options={this.state.options}   width={500} height={200}  data={this.state.data} />
                <p style ={{fontSize : "0.9em", textAlign:"center"}}><i>Funds received by wallets categorized as malicious per year spanning from 2017 until today for each abuse classification.</i> </p>
            </div>
        )
    }
}