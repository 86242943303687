
function data(){
    let data= {
        labels: [],
        datasets: [{
          label: ' Balance ',

          data: [],//this.state.transaction_balance,

          fill: true,
          borderColor: 'rgba(107, 203, 119,1)',
          backgroundColor: 'rgba(107, 203, 119,0.8)',
          tension: 0.1,


          stepped: true,
        }]
      }
      return data;
}
export default data;