import React, { Component } from "react";
import '../../App.css';
import '../../footer.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import configData from "../../configs/ip.json"

export default class Footer extends Component {

    
    getUrls() {
        let sourcesData = this.state.sourcesData;
        let counter = 1
        return (
            <p>
                {Object.entries(sourcesData).map(([key, value]) => {
                    if (value.Used === "yes") {
                        return (
                            
                            <a key={value.Url} href={value.Url} style={{marginRight:'10px'}}>{value["Website Name"]}</a>
                        );
                    }
                })}
            </p>
        );
    }
    async componentDidMount() {




        const rawSourcesData = await fetch(`${configData.clientIP}:${configData.serverPort}/sources/getSources/`)
        const sourcesData = await rawSourcesData.json()
        
        this.setState({ 'sourcesData': sourcesData })


    }

    
    constructor(props) {
        super(props)

        this.state = {
            sourcesData: []
        }

    }


    render() {

        const style = {
            color:"white",
            backgroundColor:  '#05131D' 
            
            //borderStyle: 'hidden solid hidden'
        }

        return (

            // <div style={{ textalign: "center", marginTop:'150px', backgroundColor: 'white'}}>
            //     <br />
            //     <br />
            //     <br />
            //     <br />
            //     <p style={{ color: "gray" , verticalAlign:'botom'}}>
            //         This site gets data from </p>{this.getUrls()}<p>
            //         <br /> The site was created by Arakas Ioannis
            //     </p>
            //     <br />
            //     <br />
            //     <br />
            //     <br />
            // </div>
            <Container fluid  style={style}>
                <br/>
                <br/>
                <Row>
                    <Col className='mt-4 ms-5 me-4 mb-4' style={{padding:"40px"}}>
                        <h5> Info </h5>
                        <br/>
                        <p >This site gathers data from reports of cryptocurrency wallets involved in illegal activities and analyzes the information of their transactions to estimate the amount of funds in circulation associated with illegal activities.</p>    
                    </Col>
                    <Col className='mt-4 ms-5' style={{padding:"40px"}}  >
                        <h5> Data </h5>
                        <br/>
                        <p >The data provided by this site is gathered from various <a href={`${configData.clientIP}:${configData.clientPort}/sources`}>sources. </a></p>
                    </Col>
                    <Col className='mt-4 ms-5 mb-4' style={{padding:"40px"}}>
                        <h5> About us </h5>
                        <br/>
                        <p>Developers : Ioannis Arakas, Alexandros Karagiannis</p>
                        <p >Institute:
                                <a style={{marginLeft:"10px"}} href='https://www.ics.forth.gr/'>Foundation for Research and Technology - Hellas (FORTH)
                                Institute of Computer Science</a>
                                <br></br>
                                Tel.: <a href='tel:+30 2810 391600'>+30 2810 391600</a><br></br>
                                Fax: <a href='tel:+30 2810 391601'>+30 2810 391601</a><br></br>
                                Email: <a href='mailto:ics@ics.forth.gr'>ics@ics.forth.gr</a></p>
                    </Col>
                </Row>
            </Container>
        );

    }

}