
import React, { Component } from "react";
import { Row, Col, Container } from 'react-bootstrap'
import { useParams, useNavigate } from "react-router";
import '../../App.css';


import configData from '../../configs/ip.json'
import App from "../../App";
import BitcoinRates from "./bitcoinRates";
import EthereumRates from "./ethereumRates";
import SummaryTable from "./summaryTable";
import DeFiCrime from './defiSummary'
import SiteInfo from "./siteInfo";

import AbuseTypeChart from "./abuseTypeChart";
export default class HomePage extends Component {



    render() {

        return (
            <div>
                <Container>
                    {/* #2F4354 */}
                    <Row style={{ backgroundColor: "white", margin: "2px", padding: "20px", borderRadius: "10px" }}>
                        <SiteInfo />
                    </Row>
                    <br />
                    <br />
                    <Row style={{}}>
                        <Col lg={12}><DeFiCrime style={{ padding: "20px" }} /></Col>
                    </Row>
                    <br />
                    <br />
                    <Row style={{  backgroundColor: "white", margin: "2px", padding: "30px", borderRadius: "10px" }}>
                        <br/>
                        <h3 style={{ marginLeft: "10px" }}>Stolen funds per crime classification</h3>

                        <br/>
                        <h5 style={{ marginLeft: "10px" }}>Total</h5>
                        
                        <Col style={{ padding: "20px" }} lg={12} >
                                
                            <SummaryTable />
                        </Col>
                        <p style={{ fontSize: "0.9em", textAlign:"center" }}><i>The numbers shown represent the total amount of funds transferred by crime related wallets
                            and the type of crime these funds are associated with.</i></p>

                        <Col style={{ marginTop:"100px", padding: "20px" }} lg={12} >
                                
                            <h5 style={{ marginLeft: "10px" }}>Anually</h5>
                            <AbuseTypeChart />
                        </Col>
                    </Row>
                    
                    <br />
                    <br />
                    
                    <Row >
                        <Col lg={12}>
                            <BitcoinRates />
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}