import React from 'react';
import numeral from 'numeral';
import numberStyling from "./numberStyling";

function MoneyFormatter( amount ) {
  let formattedAmount;
  if (amount >= 1 || amount === 0)
    formattedAmount = numeral(amount).format('0,0');
  else
    formattedAmount = numberStyling([numeral(amount).format('0.000'), '']);

  return <span>{formattedAmount}</span>;
}

export default MoneyFormatter;