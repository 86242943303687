import React, { Component } from "react";

// We import bootstrap to make our application look better.
import "bootstrap/dist/css/bootstrap.css";

import { Container, Col, Row, Table } from 'react-bootstrap'

import ics_logo from '../../ics-logo.png'

import logo from "../../logo.svg";

import configData from '../../configs/ip.json'

import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';


import Options from "./options";
import {
    Chart,
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js'



Chart.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    zoomPlugin);

export default class EthereumRates extends Component {


    async componentDidMount() {



        const response = await fetch(`${configData.clientIP}:${configData.serverPort}/rates/ethereum/monthly`)
        let results = await response.json();

        // let newData = this.state.data
        // newData.labels = Object.keys(results)
        // newData.datasets[0].data = ["1","2","3"]

        this.setState({
            data: {
                labels: Object.keys(results),
                datasets: [{
                    label: ' Ethereum ',

                    data: Object.values(results),//this.state.transaction_balance,

                    fill: true,
                    borderColor: 'rgb(60, 60, 61)',
                    backgroundColor: 'rgba(60, 60, 61,0.8)',
                    tension: 0.1,


                    stepped: true,
                }]
            }
        })


    }
    constructor(props) {
        super(props)

        this.state = {
            dataLabels:[],
            dataData:[],
            data: {
                labels: [],
                datasets: [{
                    label: ' Ethereum Value ',

                    data: [],

                    fill: true,
                    borderColor: 'rgba(107, 203, 119,1)',
                    backgroundColor: 'rgba(107, 203, 119,0.8)',
                    tension: 0.1,


                    stepped: true,
                }]
            }
        }
    }


    render() {

        return (
            <div style={{backgroundColor:"white", padding:"10px", borderRadius:"10px"}}>
                <h3 style={{marginLeft:"30px"}}>Ethereum rates</h3>
                <Line options={Options()} width={500} height={200} data={this.state.data} />

            </div>
        )
    }
}