import React, { useState, useEffect } from "react";
import { Pie } from 'react-chartjs-2';
import configData from "../../configs/ip.json";

const Summary = ({ heirData }) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await heirData();
        delete data['Total']

        const stateData = {
          labels: Object.keys(data),
          datasets: [{
            label: 'Money received (in millions)',
            data: Object.values(data).map(value => value.total / 1000000),
            backgroundColor: [
                '#1f77b4', '#aec7e8', '#ff7f0e', '#ffbb78', '#2ca02c', '#98df8a',
                '#d62728', '#ff9896', '#9467bd', '#c5b0d5', '#8c564b', '#c49c94',
                '#e377c2', '#f7b6d2', '#7f7f7f', '#c7c7c7', '#bcbd22', '#dbdb8d',
                '#17becf', '#9edae5', '#1a9850', '#66bd63', '#e7298a', '#ad1a9f',
                '#525252', '#666666'
            ],
            borderColor: [
              '#1f77b4', '#aec7e8', '#ff7f0e', '#ffbb78', '#2ca02c', '#98df8a',
                '#d62728', '#ff9896', '#9467bd', '#c5b0d5', '#8c564b', '#c49c94',
                '#e377c2', '#f7b6d2', '#7f7f7f', '#c7c7c7', '#bcbd22', '#dbdb8d',
                '#17becf', '#9edae5', '#1a9850', '#66bd63', '#e7298a', '#ad1a9f',
                '#525252', '#666666'
            ],
            borderWidth: 1,
            pointBackgroundColor: 'white',
            pointBorderColor: 'white',
          }]
        };

        setChartData(stateData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [heirData]);

  return (
    <div >
      {chartData && <Pie options={Options} data={chartData} />}
      
    </div>
  );
};

const Options = {
  plugins: {
    responsive: true,
    legend: {
      position: "bottom",
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true
        }
      },
      y: {
        display: true,
      }
    },
  },
};

export default Summary;
