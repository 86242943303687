import '../../App.css';

import React, { Component } from "react";

import { Chart, ArcElement,  CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler} from 'chart.js';

import { Line} from 'react-chartjs-2'



import configData from "../../configs/ip.json"
  

let options;
export default class ChartAbuseSummary extends Component {

    // async componentDidUpdate(){


        
    //     const queryString = window.location.search;
    //     const urlParams = new URLSearchParams(queryString);

    //     const type = urlParams.get('type')
    //     alert(type)
    //     this.setState({type:type})

    //     let myObj = await this.props.heirData["data"]["Total"]
    //     let labels = await this.props.heirData["labels"]

    //     var size = Object.keys(myObj).length;

    //     if(size>0){
    //         if(this.state.propsData !== myObj){
                
    //             this.setState({propsData:myObj})
                
    //             this.UpdateLine(myObj, labels)
    //         }
    //     }
    // }1000000

    async componentDidMount(){
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      const type = urlParams.get('type')
      // alert(type)
      this.setState({type:type})

      const headers = {
        "period":"anual",
        "type" :type
    };
      const rawStatisticsData = await fetch(`${configData.clientIP}:${configData.serverPort}/statistics/bitcoin/getStatistics/`, { headers })
      const statisticsData = await rawStatisticsData.json()
      

      const rawStatisticsLabelsData = await fetch(`${configData.clientIP}:${configData.serverPort}/statistics/bitcoin/getLabels/`, { headers })
      const statisticsLabelsData = await rawStatisticsLabelsData.json()


      let myObj = statisticsData["Total"]

      var size = Object.keys(myObj).length;

      if(size>0){
          if(this.state.propsData !== myObj){
              
              this.setState({propsData:myObj})
              if(Math.max(...myObj["recv"]) > 1000000)
                myObj = myObj["recv"].map(value => (value / 1000000).toFixed(3))
              else{
                myObj = myObj["recv"].map(value => (value / 1000000).toFixed(3))
                this.setState({shownAs: "Millions"})
              }

              this.UpdateLine(myObj, statisticsLabelsData)
          }
      }  
    }

    constructor(props) {
        super(props)
        Chart.register(
          ArcElement,
          CategoryScale,
          LinearScale,
          PointElement,
          LineElement,
          Title,
          Tooltip,
          Legend,
          Filler,
          
      );
        this.state ={

            propsData:[],
            
            total:0,
            num_of_addresses:0,
            number_of_txs:0,
            per: "anual",
            lastWhat:"forever",
            shownAs:"Millions",

            type:"",

            database:{},

            data: {
                labels: [],
                datasets: []
            },

        }


    
    }

    
    UpdateLine(data, labels){
        
      
        let Datasets = [];
        
          
        let obj = {

            label: `Anual funds received by malicious actors categorized as ${this.state.type}`,

            data: data,

            
            fill: true,
            

            borderColor:  'rgb(4,54,74)',
            backgroundColor:  'rgb(4,54,74, 0.8)',

            borderWidth: 1

        }
          Datasets.push(obj)
        
        
        let state_data = {
          labels: labels,
          datasets: Datasets
  
        }

        this.setState({ data: state_data  , total : data.total , number_of_txs: data.number_of_txs , num_of_addresses: data.num_of_addresses})
  
    }
    render(){
      
      options = {
  
        responsive: true,
        // maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
          },
          legend: {
              display: false // set position of legend labels
          },
        },
        scales: {
          x: {
            display: true,
            title: {
              display: true
            }
          },
          y: {
            display: true,
            type:'logarithmic',
            title: {
              display: true,
              text: `Bitcoin received in ${this.state.shownAs} of Euros €`
            },
      
            min: 0,
          }
        },
        pan: {
          enabled: true,
          mode: "xy",
          speed: 10
        },
      
      }
        return(
            <div >
                {/* <h1>hello</h1> */}
                <Line options={options}  ref={(ref)=>{this.myRef = ref}}  width={500} height={200}  data={this.state.data} />
                <p style ={{fontSize : "0.9em", textAlign:"center"}}><i>Funds received by wallets categorized as {this.state.type} per year spanning from 2017 until today.</i> </p>
            </div>
        )
    }
}