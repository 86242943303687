import React, { Component } from "react";
import { Row, Col } from 'react-bootstrap'
import '../../App.css';

function IntroText(transactionsData) {

    if(Object.keys(transactionsData).length>0){
        return (
            <div>
                
                This address has transacted {transactionsData.n_tx} times on the Bitcoin blockchain. It has received a total of {transactionsData.total_received.toFixed(8)} BTC and has sent a total of {transactionsData.total_sent.toFixed(8)} BTC. The current value of this address is {transactionsData.final_balance.toFixed(8)} BTC.
            
            </div>
        );
    }



}
export default IntroText