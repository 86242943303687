import React, { Component } from "react";

// We import bootstrap to make our application look better.
import "bootstrap/dist/css/bootstrap.css";

import { Container, Col, Row, Table } from 'react-bootstrap'

import ics_logo from '../../ics-logo.png'

import logo from "../../logo.svg";

import configData from '../../configs/ip.json'
import MoneyFormatter from "../../utils/MoneyFormatter";

import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';


import {
    Chart,
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js'

const Options = {
    responsive: true,

    scales: {
        x: {
            display: true,
            title: {
                display: true
            }
        },
        y: {
            display: true,
            title: {
                display: true,
                text: 'Billions of Euros €'
            },
            min: 0,
        }
    },
    pan:{
        enabled: true,
        mode:"xy",
        speed:10
    },
}

Chart.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    zoomPlugin);

export default class SiteInfo extends Component {


    async componentDidMount() {


        const rawStatisticsData = await fetch(`${configData.clientIP}:${configData.serverPort}/statistics-site`)
        const statisticsData = await rawStatisticsData.json()
        this.setState({statisticsData:statisticsData})

       
        // const statisticsDataTotalRecv = statisticsData["Total"]["recv"]

        // const data = statisticsDataTotalRecv.map(number => (number / 1000000).toFixed(2));
      


    }
    constructor(props) {
        super(props)

        this.state = {
            statisticsData:{},
            
           
        }
    }


    render() {

        return (
            <div style={{  backgroundColor: this.state.darktheme ?"#2F4354" : "white", padding:"10px",  borderRadius:"10px"}}>
                <br/>
                <h3 style ={{marginLeft:"10px"}}>Summary</h3>

                <div style={{marginTop:"20px"}}>
                    <div>

                        <h6 style={{ display: 'inline-block', marginLeft: "50px", width: "20%" }}>Tracked Addresses : </h6>
                        <div style={{ display: 'inline-block', color: "grey" }}> {MoneyFormatter(this.state.statisticsData.n_addr)} </div>
                    </div>
                    <div>

                        <h6 style={{ display: 'inline-block', marginLeft: "50px", width: "20%" }}>Tracked Transactions: </h6>
                        <div style={{ display: 'inline-block', color: "grey" }}> { MoneyFormatter(this.state.statisticsData.n_tx)} </div>
                    </div>

                    <div>
                        <h6 style={{ display: 'inline-block', marginLeft: "50px", width: "20%" }}>BTC : </h6>
                        <div style={{ display: 'inline-block', color: "grey" }}>{ MoneyFormatter(Math.floor(this.state.statisticsData.btc))}{" BTC"}</div>
                    </div>

                    <div>
                        <h6 style={{ display: 'inline-block', marginLeft: "50px", width: "20%" }}>Euro €: </h6>
                        <div style={{ display: 'inline-block', color: "grey" }}>{MoneyFormatter(Math.floor(this.state.statisticsData.eur))}{" EURO"}</div>
                    </div>


                </div>

            </div>
        )
    }
}