
import React, { Component } from "react";

import '../../App.css';


import { Table} from 'react-bootstrap'
  
import MoneyFormatter from "../../utils/MoneyFormatter";
import modifyMoneyValues from "../../utils/ModifyMoneyValues";
import { Button } from 'react-bootstrap'

export default class TableAbuse extends Component {
    
    componentWillMount(){
        
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const type = urlParams.get('type')

        this.setState({"type":type})
        this.updateTable('total')
    }

    constructor(props) {
        super(props)
        this.state ={

            type:''
        }
        this.total = null;
        this.ascending = true;
        this.sort = 'total'

    }   

    updateTable(sort){
        if(this.sort === sort)
            this.ascending = !(this.ascending);
        else{
            this.sort = sort;
            this.ascending = false;
        }
        this.forceUpdate();
    }
    
    counter(count, size, ascending){
        if(!ascending)
            return count;
        return size - count + 1;
    }

    sortTable(data, column, ascending){
        
        let sorted;
        let counter= 1;
        
        //delete data["Total"]
        // data = Object.keys(data).filter(key => key !== "total");

        let size = Object.keys(data).length;
        if (this.total === null){
            for(let i in data){
                if(i === 'Total'){
                    this.total = (
                    <tr key='total'>
                             <td style={{textAlign:"left", paddingLeft:"2%", paddingTop:"1%", backgroundColor: "#e6e6e6"}} > </td>
                             <td style ={{textAlign:"left", paddingLeft:"1%",  backgroundColor : "#e6e6e6"}}>Total<Button variant="none" title = "The total value equals the values of all unique addresses amongst all sources and not the sum of the values of all sources.">
                                        &#9432;
                                    </Button></td>
                             <td style={{textAlign:"left", paddingLeft:"2%", paddingTop:"1%", backgroundColor: "#e6e6e6"}} ><a href={`/stats?type=${this.state.type}&source=Total`}>{MoneyFormatter(data[i].num_of_addresses)}</a></td>
                             <td style={{textAlign:"left", paddingLeft:"2%", paddingTop:"1%", backgroundColor: "#e6e6e6"}}>{MoneyFormatter(data[i].number_of_txs)}</td>
                             <td style={{textAlign:"left", paddingLeft:"2%", paddingTop:"1%", backgroundColor: "#e6e6e6"}}>{MoneyFormatter(data[i].totalBtc)}</td>
                             <td id="value" style={{textAlign:"left", paddingLeft:"2%", paddingTop:"1%", backgroundColor: "#e6e6e6"}}>{modifyMoneyValues(data[i].total)}</td>
                         </tr>);
                }
            }
        } 
        if(column !== "source"){
            sorted = [].concat(Object.keys(data)).sort((a, b) => data[a][column] < data[b][column] ? 1 : -1).map((key) => {
                if (key !== 'Total') {
                    return (
                <tr key={key}>
                    
                    <td style={{textAlign:"left", paddingLeft:"1%"}}>{this.counter(counter++, size, ascending)} </td>
                    <td style={{textAlign:"left", paddingLeft:"1%", textOverflow: "ellipsis"}}>{key}</td>
                    
                    <td style={{textAlign:"left", paddingLeft:"2%"}} ><a href={`/stats?type=${this.state.type}&source=${key}`}>{MoneyFormatter(data[key].num_of_addresses)}</a></td>
                    <td style={{textAlign:"left", paddingLeft:"2%"}}>{MoneyFormatter(data[key].number_of_txs)}</td>
                    <td style={{textAlign:"left", paddingLeft:"2%"}}>{MoneyFormatter(data[key].totalBtc)}</td>
                    <td id="value" style={{textAlign:"left", "paddingLeft":"2%"}}>{modifyMoneyValues(data[key].total)}</td>
                </tr>
                );
            }
        });
        }
        else{
            sorted = [].concat(Object.keys(data)).sort((a, b) => a > b ? 1 : -1).map((key) => {
                if (key !== 'Total') {
                    return (
                        <tr key={key}>
                            <td style={{textAlign:"left", paddingLeft:"1%"}}>{this.counter(counter++, size, ascending)}</td>
                            <td style={{textAlign:"left", paddingLeft:"1%"}}>{key}</td>                    
                            <td style={{textAlign:"left", paddingLeft:"2%"}}><a href={`/stats?type=${this.state.type}&source=${key}`}>{MoneyFormatter(data[key].num_of_addresses)}</a></td>
                            <td style={{textAlign:"left", paddingLeft:"2%"}}>{MoneyFormatter(data[key].number_of_txs)}</td>
                            <td style={{textAlign:"left", paddingLeft:"2%"}}>{MoneyFormatter(data[key].totalBtc)}</td>
                            <td id="value" style={{textAlign:"left", "paddingLeft":"2%"}}>{modifyMoneyValues(data[key].total)}</td>
                        </tr>
                    );
                }
            });
            
        }
        if(ascending)
            sorted.reverse();
        sorted.push(this.total);
        return sorted;
        
    }

    getTableValues(){
    
        let data = this.props.heirData;
        let sort = this.sort;
        let asc = this.ascending;
        // data = Object.keys(data).filter(key => key !== "total");
        
        let sortedData = this.sortTable(data, sort, asc)
        // let total = sortedData[0];
        // sortedData = sortedData.filter(function (item){ return item !== sortedData[0]})
        // sortedData.push(total)
        return (
          <tbody>
              {sortedData}
          </tbody>
        );
        
    }

    render() {
        return (

            <div style={{padding:"40px",}}> 
                <h3>Information for each source for wallets categorized as {this.state.type} wallets</h3>
                <br></br>
                
                <Table striped bordered hover variant ={"white"} >
                    <thead>
                        <tr key={'titles'}>
                            <th>No. </th>
                        <th>Source <button className="btn" data-sort="none" onClick={() => this.updateTable('source')}><i className="fa fa-sort"></i></button></th>
                        <th>Number of Wallets <button className="btn " data-sort="none" onClick={() => this.updateTable('num_of_addresses')}><i className="fa fa-sort"></i></button></th>
                        <th>Number of Transactions <button className="btn " data-sort="none" onClick={() => this.updateTable('number_of_txs')}><i className="fa fa-sort"></i></button></th>
                        <th>Bitcoin received <button className="btn " data-sort="none" onClick={() => this.updateTable('totalBtc')}><i className="fa fa-sort"></i></button></th>
                        <th>Bitcoin received in Euros € <button className="btn " data-sort="none" onClick={() => this.updateTable('total')}><i className="fa fa-sort"></i></button></th>
                        </tr>
                    </thead>

                    {this.getTableValues()}
                </Table>
                <br></br>
                <p style ={{fontSize : "0.9em"}}><i>Table containing detailed information for the data categorized as {this.state.type}, divided per source. For each source, contains the name, number of wallets found in source, total number of transactions of wallets found in source, 
                    the amount of bitcoin received amongst all wallets and the value of the transactions in Euro, converted from BTC to € according to the rate at the time of each transaction.
                   </i></p>
            </div>
        )
    }
}