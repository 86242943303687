function Options() {
    let options = {
        responsive: true,


        scales: {
            x: {
                display: true,
                title: {
                    display: true
                }
            },
            y: {
                display: true,
                title: {
                    display: true,
                    text: 'Bitcoin'
                },
                
                min: 0,
            }
        },
        pan:{
            enabled: true,
            mode:"xy",
            speed:10
        },
        zoom: {
            enabled: true,
            // drag:false,
            mode: 'xy',
            // rangeMin:{
            //     x:0,
            //     y:0
            // }
        },


        plugins: {
            zoom: {
                // pan: {
                //     enabled: true,
                //     mode: 'xy'
                // },
                limits: {
                    y:{min:0}
                },
                zoom: {
                    drag:{
                        enabled:true, 
                        borderColor:'rgba(107, 203, 119,1)',
                        backgroundColor: 'rgba(107, 203, 119,0.2)' 
                    },
                    wheel: {
                        enabled: true,
                    },
                    pinch: {
                        enabled: true
                    },
                    mode: 'xy',
                }
            }
        },
            // legend: {
            //     position: 'bottom',
            //     height: "200px",
            // },

        
        onClick(e) {
            console.log(e.type);
        }
    }
    return options
}
export default Options