import React, { Component } from "react";

// We import bootstrap to make our application look better.
import "bootstrap/dist/css/bootstrap.css";

import { Container, Form, Navbar, Nav, Col , Row , NavDropdown} from 'react-bootstrap'

import ics_logo from '../../ics-logo.png'

import logo from "../../logo.svg";

import configData from '../../configs/ip.json'
export default class Bar extends Component {

    constructor(props) {
        super(props)
    
        this.onChangeSearchString = this.onChangeSearchString.bind(this);
        this.onSubmit = this.onSubmit.bind(this)
    
        this.state = {
          SearchString: ""
        };
    
    }
    
    onChangeSearchString(e) {
        this.setState({ SearchString: e.target.value });
    }
    
    onSubmit(e) {
        e.preventDefault();
        // alert(this.state.SearchString)
        this.searchAddress(this.state.SearchString)
    }

    async searchAddress(address){
      const bitcoinRegex = new RegExp('^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,39}'); 
      const ethereum = new RegExp('^0x[a-fA-F0-9]{40}'); 


      const headers = {
        'addr':address
      }
      
      if( bitcoinRegex.test(address)){

        const rawTransactionsData = await fetch(`${configData.clientIP}:${configData.serverPort}/address/bitcoinWalletExist/`, { headers })
        const transactionsData = await rawTransactionsData.json()

        if(transactionsData.fileExist == false){
          alert(`The bitcoin ${address} address doesn't exist`)
        }else{
          window.location.href = `/address/?addr=${address}`;
        }
        

      }
    }

    getAppLogo(){
        
          if(window.innerWidth>500){
            
            return(                    <img
              alt=""
              src={ics_logo}
              width="160px"
              height="auto"
              className="d-inline-block align-top"
            />)
          }else{
            
            return(<></>)
          }
        
      
    }
    render() {
      return (
        <div>
          <Navbar bg="dark" variant="dark">
            <Container>
              <Navbar.Brand href="/">
                <Row style={{}}>
                  <Col>
                    <this.getAppLogo/>
                  </Col>
  
                  <Col >
                    <h2>Crypto Abuses</h2>
                  </Col>
                </Row>
              </Navbar.Brand>
              
              <Nav className="me-auto">
              {/* <NavDropdown title="Abuses" id="navbarScrollingDropdown">
                <NavDropdown.Item  href={`/statistics?type=All`}>All</NavDropdown.Item>
                <NavDropdown.Item  href={`/statistics?type=Ransomware`} >Ransmoware</NavDropdown.Item>
                <NavDropdown.Item  href={`/statistics?type=BlackmailScam`}>Blackmail or Scam</NavDropdown.Item>
                <NavDropdown.Item  href={`/statistics?type=BitcoinTumbler`}>Bitcoin Tumbler</NavDropdown.Item>
                <NavDropdown.Item  href={`/statistics?type=DarknetMarket`}>Darknet Market</NavDropdown.Item>
                <NavDropdown.Item  href={`/statistics?type=Extortion`} >Extortion</NavDropdown.Item>
                <NavDropdown.Item  href={`/statistics?type=Other`}>Other</NavDropdown.Item>

              </NavDropdown> */}
                <Nav.Link href="/sources">Sources</Nav.Link>
              </Nav>
               
  
            </Container>
          </Navbar>
        </div>
      );
    }
  }