function Options() {
    return {
        responsive: true,


        scales: {
            x: {
                display: true,
                title: {
                    display: true
                }
            },
            y: {
                display: true,
                title: {
                    display: true,
                    text: 'Rates'
                },
                
                min: 0,
            }
        },
        pan:{
            enabled: true,
            mode:"xy",
            speed:10
        },


        plugins: {
            datalabels: {
                display: false,
                anchor: 'end',
                align: 'end',
                formatter: (value, context) => value, // Display the actual value
            } 
        }
        //     zoom: {
        //         limits: {
        //             y:{min:0}
        //         },
        //         zoom: {
        //             drag:{
        //                 enabled:true, 
        //                 borderColor:'rgb(247, 147, 26)',
        //                 backgroundColor: 'rgba(247, 147, 26, 0.2)' 
        //             },
        //             wheel: {
        //                 enabled: true,
        //             },
        //             pinch: {
        //                 enabled: true
        //             },
        //             mode: 'xy',
        //         }
        //     }
        // },
        
    }
}
export default Options;