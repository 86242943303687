import numberStyling from "./numberStyling";

export function modifyMoneyValues(value){
    if(value <= 1000)
        return numberStyling([value.toFixed(2), '']);
    else if(value < 1000000)
        return numberStyling([(value/1000).toFixed(2), ' K']);
    else if(value < 1000000000)
        return numberStyling([(value/1000000).toFixed(2), ' M']);
    else
        return numberStyling([(value/1000000000).toFixed(2), ' B']);
}
  
export default modifyMoneyValues