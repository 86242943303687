import React, { Component } from "react";
import { Row, Col , Button} from 'react-bootstrap'
import '../../App.css';


import { Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';

import Options from './options.js'
import Data from './data.js'
import ConvertUnixTimestampToTime
 from "./convertUnixTimestampToTime";
import {
    Chart,
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
  } from 'chart.js'
  
  
  
  Chart.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    zoomPlugin);
  
const eight = 0.00000001;
export default class AddressChart extends Component {

    
    constructor(props) {
        super(props);


        this.myRef = React.createRef();
    
        this.state = {
          address: "",
          number_of_transactions: 0,
          received: 0,
          sent: 0,
          bitcoinBalance: 0,
          balance: 0,
          date: [],
          transaction_balance: [],
          tmp_balance: 0,
    
          data: Data(),
          options: Options(),
    
          report_source: [] ,
    
    
        };
    
    }

    ParseJsonFile(data) {

    
        this.setWalletInfo(data)
    
        let tmp_balance = this.state.tmp_balance
        
    
        data["txs"].forEach(obj => {
    
    
    
          let input_data = obj["inputs"];
          let transaction_sum = 0;
    
          input_data.forEach(transaction => {
    
            if (transaction["prev_out"]["addr"] === this.state.address) {
    
              transaction["prev_out"].value *= eight
              transaction_sum += transaction["prev_out"].value
    
            }
    
    
          })
          
          
          tmp_balance += transaction_sum
    
          transaction_sum = tmp_balance
    
          let time = ConvertUnixTimestampToTime(obj["time"])
    
          this.state.date.push(time);
          this.state.transaction_balance.push(transaction_sum)
    
    
    
    
    
          let output_data = obj["out"];
          output_data.forEach(transaction => {
            if (transaction["addr"] === this.state.address) {
    
              transaction.value *= eight
    
              tmp_balance -= transaction.value
    
    
    
              transaction.value = tmp_balance
    
              let time = ConvertUnixTimestampToTime(obj["time"])
    
              this.state.date.push(time);
              this.state.transaction_balance.push(transaction.value)
    
    
            }
          })
    
        })
    
    
      }
    

    async componentDidUpdate() {

        const queryString = window.location.search;
        
        const urlParams = new URLSearchParams(queryString);
        const addr = urlParams.get('addr')
        this.setState({address:addr})

        this.ParseJsonFile(this.props.parentData)

        
        this.setState({data:{
          labels: this.state.date.reverse(),
          datasets:[{ 
            data: this.state.transaction_balance.reverse(),
            
            label: ' Balance ',
            fill: true,
            borderColor: 'rgba(107, 203, 119,1)',
            backgroundColor: 'rgba(107, 203, 119,0.8)',
            tension: 0.1,
    
    
            stepped: true,
          }]
        }})
        
        
        let options = Options()
        options.scales.y.min=0
        options.scales.y.max = this.state.received + (this.state.received/10)
        this.setState({options:options}) 
    
    }
    setWalletInfo(data){
        this.setState({
          address : data["address"],
          number_of_transactions : data["n_tx"],
          received : data["total_received"] * eight,
          sent : data["total_sent"] * eight,
          balance : data["final_balance"] * eight,
    
    
    
          tmp_balance : data["final_balance"] * eight,
    
        })
      }
    

    render(){
        return(
            <div>
                
                <h2> Wallet Balance History </h2>
                
                <Line options={this.state.options} ref={(ref)=>{this.myRef = ref}} width={500} height={200} data={this.state.data}  />
                <Button variant="secondary" onClick={() => { this.myRef.resetZoom() }} >Reset Zoom</Button>
            </div>

        )
    }



}