
import React, { Component } from "react";

import '../../App.css';
import { Row, Col, Container, Table } from 'react-bootstrap'

import axios from 'axios';

import configData from "../../configs/ip.json"
import Intersection from "./intersections";


// import ChartEthereumAbuse from "./chartEthereumAbuse";
export default class Sources extends Component {



    getTableValues() {
        let sourcesData = this.state.sourcesData;
        let counter = 1
        return (
          <tbody>
            {Object.entries(sourcesData).map(([key, value]) => {
                return (
                  <tr key={key}>
                    <td>{counter++}</td>
                    <td><a href={value.Url}>{value["Website Name"]}</a></td>
                    <td>{value["Type"]}</td>
                    <td>{value.Description}</td>
                  </tr>
                );
              
            })}
          </tbody>
        );
    }
    async componentDidMount() {




        const rawSourcesData = await fetch(`${configData.clientIP}:${configData.serverPort}/sources/getSources/`)
        const sourcesData = await rawSourcesData.json()
        
        this.setState({ 'sourcesData': sourcesData })


    }

    constructor(props) {
        super(props)

        this.state = {
            sourcesData: []
        }

    }



    render() {
        return (
            <div>

                <Container>
                    
                    <Row>
                        <Col md={{ span: 12, offset: 0 }} style={{ textAlign: 'left' , backgroundColor:"white", padding:"50px", borderRadius:"10px"}}>
                            
                            <Intersection/>
                            <br/>
                            <br/>
                            <p style={{ fontSize: "0.9em", textAlign:"center" }}><i>This ven diagram shows the intersection of all the sources that have been used in this website.</i></p>

                        </Col>
                    </Row>
                    <br/>
                    <Row >

                        <Col md={{ span: 12, offset: 0 }} style={{ textAlign: 'left' , backgroundColor:"white", padding:"70px", borderRadius:"10px"}}>
                            <h3> Sources</h3>
                            <br/>
                            <Table striped bordered hover >
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Website</th>
                                        <th style={{width:"25%"}}>Source Type</th>
                                        <th>Info</th>
                                    </tr>
                                </thead>
                                {this.getTableValues()}

                            </Table>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}