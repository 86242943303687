import '../../App.css';

import React, { Component } from "react";


import colors from '../../configs/colors.json'
import backgroundcolors from '../../configs/backgroundColors.json'
import {Bar, Line} from 'react-chartjs-2'

import configData from "../../configs/ip.json"
  

const options = {
  
    responsive: true,
    plugins: {
      datalabels: {
        display:false
      } ,
      title: {
        display: true,
      }
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true
        }
      },
      y: {
        display: true,
        // type:'logarithmic',
        title: {
          display: true,
          text: 'Bitcoin received in Millions of Euros €'
        },
  
        min: 0,
      }
    },
    
    pan: {
      enabled: true,
      mode: "xy",
      speed: 10
    },
  
  }
export default class ChartAbuse extends Component {

    async componentDidMount(){       

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      const type = urlParams.get('type')
      this.setState({type:type})
      const headers = {
          "period":"total",
          "type" :type
      };

      const rawStatisticsData = await fetch(`${configData.clientIP}:${configData.serverPort}/statistics/bitcoin/getStatistics/`, { headers })
      const statisticsData = await rawStatisticsData.json()
      

      const rawStatisticsLabelsData = await fetch(`${configData.clientIP}:${configData.serverPort}/statistics/bitcoin/getLabels/`, { headers })
      const statisticsLabelsData = await rawStatisticsLabelsData.json()

      // const obj = {"data":statisticsData , "labels":statisticsLabelsData }

        var size = Object.keys(statisticsData).length;
        if(size>0){
            if(this.state.propsData !== statisticsData){
                
                this.setState({propsData:statisticsData})
                
                this.UpdateBar(statisticsData, statisticsLabelsData)
            }
        }
  }

    constructor(props) {
        super(props)
    
        this.state ={

            propsData:[],
            allowed_options:  [{key:"year", value:"1Y"},{key:"forever", value:"Max"}] ,

            total:0,
            num_of_addresses:0,
            number_of_txs:0,
            per: "anual",
            lastWhat:"forever",

            type:"",

            database:{},
            
            data: {
                labels: [],
                datasets: []
            },
            

        }


    
    }

    UpdateBar(data, labels){
        
      
        let Datasets = [];
        
        for( let db in data){
          if(db !== "Total"){
            let obj = {
              label: db,
    
              data: data[db]["recv"].map(value => (value / 1000000).toFixed(2)),
    
              
              fill: true,
              
              borderColor: colors[db],
              backgroundColor: backgroundcolors[db],
    
              borderWidth: 1
            }
            Datasets.push(obj)
          }
        }
        
        let state_data = {
          labels: labels,
          datasets: Datasets
  
        }

        this.setState({ data: state_data  , total : data.total , number_of_txs: data.number_of_txs , num_of_addresses: data.num_of_addresses})
  
    }

    render(){
      
        return(
            <div style ={{
            }}>
                <h3 style ={{marginLeft:"30px "}}>Total amount of Euro received in each source</h3>
                <Bar options={options}  ref={(ref)=>{this.myRef = ref}}  width={500} height={200}  data={this.state.data} />
                <p style ={{textAlign:"center", fontSize : "0.9em"}}><i>The amounts gathered per sources from 2017 until today.</i></p>
            </div>
        )
    }
}