
import React, { Component } from "react";

import '../../App.css';
import { Row, Col, Container, Table, Button } from 'react-bootstrap'

import axios from 'axios';
import { ArcElement, Tooltip, Legend } from 'chart.js';
import Chart from "chart.js/auto";
import { Pie } from 'react-chartjs-2';
import { MdContentCopy } from 'react-icons/md'; // Import the copy icon from React Icons


import configData from "../../configs/ip.json"
import AddressPopup from "./addressPopup";
import 'font-awesome/css/font-awesome.min.css';
import blockchainicon from "../../favicon1.ico"
import numberStyling from "../../utils/numberStyling";

import copy from "../../copy.png"

const pageSize = 20
export default class Statistics extends Component {

    async getAddresses(page) {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const type = urlParams.get('type')
        const source = urlParams.get('source')

        const headers = {
            "source": source,
            "type": type
        };


        const rawStatisticsData = await fetch(`${configData.clientIP}:${configData.serverPort}/statistics?page=${page}`, { headers })
        const statisticsData = await rawStatisticsData.json()

        this.setState({ "numOfPages": statisticsData["numOfPages"] })
        this.setState({ 'statisticsData': statisticsData["addresses"] })
    }
    async componentDidMount() {



        this.getAddresses(1)

    }
    handleCopyToClipboard = (text) => {
        const textField = document.createElement('textarea');
        textField.innerText = text;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
    };
    getTableValues() {

        let data = this.state.statisticsData

        return (
            <tbody>
                {Object.entries(data).map(([key, value], index) => (
                    <tr key={key}>
                        <td>{((this.state.page - 1) * pageSize) + index + 1}</td>
                        <td >
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' , whiteSpace:'nowrap'}}>
                                <div style={{ display: 'inline-block' }}>
                                    <p style={{ display: 'inline-block', marginRight: '10px' }}>{key}</p>
                                </div>
                                <div style={{ display: 'inline-block' }}>
                                    <Button variant="none" title = "View more address info" onClick={() => { window.open(`/address?addr=${key}`) }}>
                                        <i className="fa fa-external-link" />
                                    </Button>
                                    <Button  variant="none" title = "Copy address" onClick={() => { this.handleCopyToClipboard(key) }}>
                                        <img style={{ width: "30px" }} src={copy} />
                                    </Button>
                                    <Button variant="none" title = "View address on blockchain.com" onClick={() => window.open(`https://www.blockchain.com/explorer/addresses/btc/${key}`)}>
                                        <img style={{ width: "20px" }} src={blockchainicon} alt="Blockchain.com" />
                                    </Button>
                                </div>
                            </div>
                        </td>

                        {/* <td><a href={`https://www.blockchain.com/explorer/addresses/btc/${key}`} >{key}</a></td> */}
                        <td style={{ textAlign: "right" }}>{numberStyling([value["bitcoin_value"], ''])}</td>
                        <td style={{ textAlign: "right" }}>{numberStyling([value["regular_value"], ''])}</td>
                    </tr>

                ))}
            </tbody>
        );
    }

    constructor(props) {
        super(props)

        this.state = {
            source: "",
            type: "",
            statisticsData: {},
            numOfPages: 1,
            page: 1,

            addressPopup: false,
            addressValues: ""

        }



    }

    closePopup = () => {
        this.setState({ addressPopup: false })
    }

    handlePageChange(page) {
        if (page >= 1 && page <= Math.ceil(this.state.numOfPages / 20)) {

            this.setState({ page: page })
            this.getAddresses(page);
        }
    };
    render() {
        return (

            <div>   

                <Container style={{ backgroundColor: "white", padding: "10px", borderRadius: "10px" }}>
                    <Row style={{marginTop:"50px"}}>

                        <Col md={{ span: 10, offset: 1 }} style={{ textAlign: 'left' }}>
                            <h3>Detailed Analysis of {this.state.source} for the abuse type {this.state.type}</h3>
                        </Col>
                    </Row>
                    <br />
                    <br />
                    <Row style={{}}>

                        <Col md={{ span: 10, offset: 1 }} style={{ textAlign: 'left' }}>
                            <Table striped bordered hover >
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Wallet Address</th>
                                        <th style ={{whiteSpace:'nowrap'}}>Bitcoin Amount</th>
                                        <th style ={{whiteSpace:'nowrap'}}>Bitcoin Amount Calculated in Euro €</th>
                                    </tr>
                                </thead>

                                {this.getTableValues()}
                            </Table>
                        </Col>
                    </Row>
                    <Row>

                        <div style={{ textAlign: "center", marginTop: "20px", marginBottom: "20px" }}>
                            <Button variant="secondary" onClick={() => this.handlePageChange(this.state.page - 1)}>Previous</Button>
                            <span style={{ marginLeft: "20px", marginRight: "20px" }}>Page {this.state.page} of {Math.ceil(this.state.numOfPages / 20)}</span>
                            <Button variant="secondary" onClick={() => this.handlePageChange(this.state.page + 1)}>Next</Button>
                        </div>
                    </Row>
                </Container>
                {this.state.addressPopup && <AddressPopup onReviewClose={this.closePopup} initialValues={this.state.addressValues} />}
            </div>
        )
    }
}