
import React, { Component } from "react";

import '../../App.css';
import {Row, Col, Container} from 'react-bootstrap'

import axios from 'axios';
import {  ArcElement, Tooltip, Legend } from 'chart.js';
import Chart from "chart.js/auto";
import { Pie } from 'react-chartjs-2';

 
import Summary from "./summary";
import configData from "../../configs/ip.json"
import TableAbuse from "./tableAbuse";
import ChartAbuse from "./chartAbuse"; 
import ChartAbuseSummary from './chartAbuseSummary'
import Intersection from "./Intersection";
// import ChartEthereumAbuse from "./chartEthereumAbuse";
export default class Abuse extends Component {
   

      
    async componentDidMount(){
       


        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const type = urlParams.get('type')
        this.setState({type:type})
        const headers = {
            "period":"anual",
            "type" :type
        };

        const rawStatisticsData = await fetch(`${configData.clientIP}:${configData.serverPort}/statistics/bitcoin/getStatistics/`, { headers })
        const statisticsData = await rawStatisticsData.json()
        

        const rawStatisticsLabelsData = await fetch(`${configData.clientIP}:${configData.serverPort}/statistics/bitcoin/getLabels/`, { headers })
        const statisticsLabelsData = await rawStatisticsLabelsData.json()

        const obj = {"data":statisticsData , "labels":statisticsLabelsData }
        this.setState({'statisticsData': statisticsData})
        this.setState({'data': obj})
           
        
        
        // const rawStatisticsEthereumData = await fetch(`${configData.clientIP}:${configData.serverPort}/statistics/ethereum/getStatistics/`, { headers })
        // const statisticsEthereumData = await rawStatisticsEthereumData.json()
        // this.setState({'statisticsEthereumData': statisticsEthereumData})
        
        
    }

    constructor(props) {
        super(props)
    

        this.DropdownPer = this.DropdownPer.bind(this)

        this.state = {
            data:{},
            statisticsData : {},
            statisticsEthereumData:{},
            per: "anual",

            darktheme: localStorage.getItem('darktheme'),
           
        }


    
    }


    async DropdownPer(e){
        
        const period = e.target.value

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const type = urlParams.get('type')

        const headers = {
            "period": period,
            "type" : type
        };
        const rawStatisticsData = await fetch(`${configData.clientIP}:${configData.serverPort}/statistics/bitcoin/getStatistics/`, { headers })
        const statisticsData = await rawStatisticsData.json()
        
        const rawStatisticsLabelsData = await fetch(`${configData.clientIP}:${configData.serverPort}/statistics/bitcoin/getLabels/`, { headers })
        const statisticsLabelsData = await rawStatisticsLabelsData.json()

        const obj = {"data":statisticsData , "labels":statisticsLabelsData }
        this.setState({'statisticsData': statisticsData})
        this.setState({'data': obj})
        // this.setState({'statisticsEthereumData':statisticsEthereumData})
        

    }

    
    render() {
        return (

                <Container>
                    <Row > 
                
                        <Col  md={12} style={{ backgroundColor : "white", padding:"40px", borderRadius:"10px"}}>
                            <h3  >Anual amount of Euro received by wallets categorized as {this.state.type} wallets</h3>
                            <br/>
                            <ChartAbuseSummary/>
                        </Col>
                        <Col md={12} style={{textAlign:'left',marginTop:"50px" ,backgroundColor: "white",  padding:"10px", borderRadius:"10px"}}>
                            
                            <TableAbuse  heirData={this.state.statisticsData}/>
                            <br/>
                            <br/>
                            <div style={{paddingLeft:"25%", paddingRight:"25%"}} >
                                {this.state.statisticsData && <Summary   heirData={() => Promise.resolve(this.state.statisticsData)}  />}
                            
                                <p style ={{fontSize : "0.9em",marginTop:"50px", textAlign:"center"}}><i>Pie Chart containing the percentage of the conribution of each source categorized as {this.state.type}, divided per source. 
                                </i></p>
                            </div>
                        </Col>



                        <br/>
                        {/* <Col md={12} style={{marginTop:"50px", backgroundColor:"white", padding:"20px", borderRadius:"10px"}}>
                          
                            <br/>
                            <ChartAbuse />
                            <br/>
                            
                            
                        </Col> */}
                        
                        
                        <Col md={  12} style={{ textAlign: 'left' , backgroundColor:"white", marginTop:"70px" , padding:"50px", borderRadius:"10px"}}>
                            
                            <Intersection/>
                            <br/>
                            <br/>
                            <p style={{ fontSize: "0.9em", textAlign:"center" }}><i>This heatmap shows the intersection of all the sources that have been used in this website.</i></p>

                        </Col>
                    </Row>
                </Container>
        )
    }
}