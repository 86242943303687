import React, { Component } from "react";

// We import bootstrap to make our application look better.
import "bootstrap/dist/css/bootstrap.css";


import { Table } from 'react-bootstrap'

function AddressTableSummary(transactionsData){



    if(Object.keys(transactionsData).length>0){
        return(
                
            <div>
            <h2> Wallet Informations </h2>
                    <Table hover>

                        <tbody>
                            <tr>
                                <td>Address</td>
                                <td>{transactionsData.address}</td>
                            </tr>
                            <tr>
                                <td>Transactions</td>
                                <td>{transactionsData.n_tx}</td>
                            </tr>
                            <tr>
                                <td>Total Received</td>
                                <td>{transactionsData.total_received.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td>Total Sent</td>
                                <td>{transactionsData.total_sent.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td>Final Balance</td>
                                <td>{transactionsData.final_balance.toFixed(2)}</td>
                            </tr>
                        </tbody>
                    </Table>
            </div>
        )
        }
}

export default AddressTableSummary