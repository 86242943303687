import logo from './logo.svg';
import './App.css';

import React, { useEffect, useState } from 'react';

import { Route, Routes, BrowserRouter } from "react-router-dom";


import Bar from './components/bar/bar';
import Menu from './components/bar/menu';
import HomePage from './components/homepage/homepage';
import Footer from './components/footer/footer';
import Abuse from './components/abuse/abuse';
import Address from './components/address/address';
import Sources from './components/sources/sources'
import Statistics from './components/stats/statistics';
import DataStatistics from './components/statistics/dataStatistics';
function App() {
  


  return (
    <div>
      <Bar/>
      <div style={{ "backgroundColor":   '#EEEEEE', "paddingBottom":"150px"}}>
        <Menu/>
        <BrowserRouter >
          <Routes>
            <Route exact path="/" element={<HomePage/>} />
            <Route exact path="statistics/" element={<Abuse/>} />
            <Route exact path="temp/*" element={<DataStatistics/>} />
            <Route exact path="address/*" element={<Address/>} />
            
            <Route exact path="stats/*" element={<Statistics/>} />
            <Route exact path="sources" element={<Sources/>} />
          </Routes>
        </BrowserRouter>
      </div>
      <Footer/>
    </div>
  );
}

export default App;
