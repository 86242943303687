import React, { Component } from "react";

// We import bootstrap to make our application look better.
import "bootstrap/dist/css/bootstrap.css";

import { Container, Col, Row, Button } from 'react-bootstrap'
import axios from 'axios';
import ics_logo from '../../ics-logo.png'

import logo from "../../logo.svg";

import configData from '../../configs/ip.json'
import IntroText from "./introText";
import AddressTableSummary from "./addressTableSummary";
import AddressChart from "./addressChart";

import blockchainicon from "../../favicon1.ico"
import copy from "../../copy.png"

import MoneyFormatter from "../../utils/MoneyFormatter";

import btc from '../../btc.png';

export default class Address extends Component {

    async componentDidMount() {

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        const address = urlParams.get('addr')
        this.setState({ address: address })


        const headers = {
            'addr': address
        }
        axios.get(`${configData.clientIP}:${configData.serverPort}/address/info`, { headers }).then((response) => {

            this.setState({ sources: response["data"]["sources"], summary: response["data"]["summary"] })
            //console.log(response["data"]["summary"])

        }).catch((error) => {
            console.error(error); // Handle error
        });

        // alert(this.state.address)

        // const rawTransactionsData = await fetch(`${configData.clientIP}:${configData.serverPort}/address/getBitcoinTransactions/`, { headers })
        // const transactionsData = await rawTransactionsData.json()
        // this.setState({transactionsData:transactionsData})


    }
    getSources() {
        let sources = this.state.sources

        if (Object.keys(sources).length > 0) {
            return (
                <div style={{ marginLeft: "60px" }}>
                    <br/>
                        <div>
                            <h5 style={{ display: 'inline-block', width: "42%" }} >Source </h5>
                            <h5 style={{ display: 'inline-block' }} > classification </h5>
                            
                        </div>
                        {Object.entries(sources).map(([key, value], index) => (
                            <div>
                                <h6 style={{ display: 'inline-block', width: "42%" }} >{this.getSourceLink(key)} </h6>
                                <h6 style={{ display: 'inline-block', color: "grey" }} >{value} </h6>
                                <br/>
                            </div>
                        ))}
                </div>
            );
        } else {
            return;
        }
    };
    getSourceLink(source) {
        let address = this.state.address

        switch (source) {

            case "BitcoinAbuse":
                return < ><a style={{ display: "inline" }} href={`https://cryptscam.com/en/detail/${address}`}>Bitcoin Abuse</a><p style={{ color: "gray", display: "inline" }}> via cryptscam </p></>;

            case "CryptoScamDB":
                return <a href={`https://cryptoscamdb.org/address/${address}`}>CryptoScamDB</a>;

            case "Alienvault":
                return <a href={`https://otx.alienvault.com/indicator/bitcoin-address/${address}`}>Alienvault</a>

            case "Bitcoinais":
                return <a href={`https://bitcoinais.com/btc/${address}`}>Bitcoinais</a>

            case "OpenSanctions":
                return <a href={`https://www.opensanctions.org/entities/rwre-${address.toLowerCase()}`}>OpenSanctions</a>

            case "Kaggle":
                return <a href={`https://www.kaggle.com/datasets/sapere0/bitcoinheist-ransomware-dataset`}>Kaggle</a>

            case "Behas":
                return <a href={`https://media.githubusercontent.com/media/behas/ransomware-dataset/master/data/seed_addresses.csv`}>Behas</a>

            case "Blurpesec":
                return <a href={`https://gist.github.com/blurpesec/31842984e8d6976de50434f3d180a5a8`}>Blurpesec</a>

            case "CheckBitcoinAddress":
                return <a href={`https://checkbitcoinaddress.com/bitcoin/${address}`}>CheckBitcoinAddress</a>

            case "Cryptscam":
                return <a style={{ display: "inline" }} href={`https://cryptscam.com/en/detail/${address}`}>Cryptscam</a>

            case "SophosLab":
                return <a href={`https://github.com/sophoslabs/IoCs/blob/master/bitcoin-addys`}>SophosLab</a>

            case "ChainAbuse":
                return <a href={`https://www.chainabuse.com/address/${address}`}>ChainAbuse</a>

            case "Tessi":
                return <a href={`https://raw.githubusercontent.com/tessii/bitcoin_ransomware_addresses/main/df_ransomware.csv`}>Tessii</a>

            case "0xjxd":
                return <a href={`https://github.com/0xjxd/Conti-Leak/blob/main/BTC-Wallets_Balance_Received.csv`}>0xjxd</a>

            case "KillingTheBear":
                return <a href={`https://raw.githubusercontent.com/Ud0g-Py/Killing-the-Bear-BloodCoin/master/attack_wallets.json`}>KillingTheBear</a>

            case "Ransomlook":
                return <a href={`https://www.ransomlook.io/crypto`}>Ransomlook</a>

            case "SpamReports":
                return <a href={`https://raw.githubusercontent.com/Ud0g-Py/Killing-the-Bear-BloodCoin/master/attack_wallets.json`}>SpamReports</a>

            case "Ransomwhere":
                return <a href={`https://ransomwhe.re/index.html`}>Ransomwhere</a>

            case "Ofac":
                return <a href={`https://ofac.treasury.gov/specially-designated-nationals-and-blocked-persons-list-sdn-human-readable-lists`}>OFAC</a>

            case "Scamsearch":
                return <a href={`https://scamsearch.io`}>Scamsearch</a>

            case "Traceer":
                return <a href={`https://traceer.co`}>Traceer</a>

            //cryptoexchangesscam ?
            //etherscamdb not found


            // posibilities 
            // - bitcoinabuse
            // - scamsearch
            // - open
            default:
                return source
        }
    }

    handleCopyToClipboard = (text) => {
        const textField = document.createElement('textarea');
        textField.innerText = text;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
    };
    constructor(props) {
        super(props)

        this.state = {
            address: "",
            sources: [],
            transactionsData: {},
            summary: {}
        }

    }
    render() {
        let address = this.state.address
        return (
            <div>
                <Container>
                    <Row>

                        <Col md={{ span: 10, offset: 1 }} style={{ marginTop: "50px", backgroundColor: "white", padding: "30px", borderRadius: "10px", textAlign: 'left' }}>

                        <Container>
                            <Row>
                                <Col md={ 1} >
                                    <img style={{ height: "100px" }} alt="btc" src={btc} />
                                </Col>
                                <Col md={ 10} style={{marginLeft:"40px"}}>
                                    <div>
                                        <br/>
                                        <h3 style={{ color: "grey", display: "inline" }}>{`${address.slice(0,4)}- ${address.slice(-4)}`}</h3>
                                        <br/><br/>
                                        <h6>Bitcoin Address</h6>
                                        {address}
                                        <Button style={{ marginLeft: "10px" }} variant="none" title="Copy address" onClick={() => { this.handleCopyToClipboard(this.state.address) }}>
                                            <img alt="copy" style={{ width: "20px" }} src={copy} />
                                        </Button>

                                        <Button variant="none" title="View address on blockchain.com" onClick={() => window.open(`https://www.blockchain.com/explorer/addresses/btc/${this.state.address}`)}>
                                            <img style={{ width: "20px" }} src={blockchainicon} alt="Blockchain.com" />
                                        </Button>
                                    </div>
                                   
                                </Col>
                            </Row>
                            <Row style={{marginTop:"50px"}}>
                            <br />
                                    <br />
                                    <h4>Summary</h4>
                                    <br />

                                    <div style={{}}>
                                        <div>

                                            <h6 style={{ display: 'inline-block', marginLeft: "50px", width: "40%" }}>Number of Transactions : </h6>
                                            <div style={{ display: 'inline-block', color: "grey" }}>{this.state.summary.n_tx}</div>
                                        </div>

                                        <div>
                                            <h6 style={{ display: 'inline-block', marginLeft: "50px", width: "40%" }}>Total Money Received in BTC : </h6>
                                            <div style={{ display: 'inline-block', color: "grey" }}>{MoneyFormatter(Math.floor(this.state.summary.total_btc))}</div>
                                        </div>

                                        <div>
                                            <h6 style={{ display: 'inline-block', marginLeft: "50px", width: "40%" }}>Total Money Received in Euro €: </h6>
                                            <div style={{ display: 'inline-block', color: "grey" }}>{MoneyFormatter(this.state.summary.total_eur)}</div>
                                        </div>


                                    </div>
                            </Row>
                        </Container>
                                </Col>
                    </Row>
                    <Row>
                        <Col md={{ span: 10, offset: 1 }} style={{ marginTop: "50px", backgroundColor: "white", padding: "30px", borderRadius: "10px", textAlign: 'left' }}>
                            <h4>
                                Datasets that contain this address
                            </h4>
                            {this.getSources()}
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}