import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';

import { Button } from 'react-bootstrap';
import axios from 'axios';


import configData from "../../configs/ip.json"

// const dictionary = {
//     "CryptoScamDB":"https://cryptoscamdb.org/address/",
//     // "Scamsearch":
// }
const AddressPopup = ( {onReviewClose, initialValues}) => {

  
    const [address, setAddress] = useState('');
    const [isOpen, setIsOpen] = useState(true);
    const [sources, setSources] = useState([])
    
    useEffect(() => {
        if (initialValues) {
        
            setAddress(initialValues);
            axios.get(`${configData.clientIP}:${configData.serverPort}/address/info`,
                
                    {
                        headers: {
                            addr: initialValues,
                        },
                    }
            ).then((response) => {
                setSources(response["data"]["sources"])
                // setSources(response["data"])
            }).catch((error) => {
                console.error(error); // Handle error
            });
        }


    }, [initialValues]);

    const closePopup = () =>{
        setIsOpen(false)
        onReviewClose()
    }

    const getSourceLink = (source )=>{

        switch(source){
            
            case "BitcoinAbuse":
                return  < ><a style={{display:"inline"}} href={ `https://cryptscam.com/en/detail/${address}`}>Bitcoin Abuse</a><p style={{ color:"gray", display:"inline"}}> via cryptscam </p></>;

            case  "CryptoScamDB":
                return  <a href={ `https://cryptoscamdb.org/address/${address}`}>CryptoScamDB</a>;
            case "Alienvault":
                return  <a href={ `https://otx.alienvault.com/indicator/bitcoin-address/${address}`}>Alienvault</a>

            case "Bitcoinais":
                return  <a href={ `https://bitcoinais.com/btc/${address}`}>Bitcoinais</a>

            case "OpenSanctions":
                return  <a href={ `https://www.opensanctions.org/entities/rwre-${address.toLowerCase()}`}>OpenSanctions</a>

            case "Kaggle":
                return  <a href={ `https://www.kaggle.com/datasets/sapere0/bitcoinheist-ransomware-dataset`}>Kaggle</a>


            case "Behas":
                return  <a href={ `https://media.githubusercontent.com/media/behas/ransomware-dataset/master/data/seed_addresses.csv`}>Behas</a>
                
            case "Blurpesec":
                return  <a href={ `https://gist.github.com/blurpesec/31842984e8d6976de50434f3d180a5a8`}>Blurpesec</a>

            case "CheckBitcoinAddress":
                return  <a href={ `https://checkbitcoinaddress.com/bitcoin/`}>CheckBitcoinAddress</a>


            case "Cryptscam":
                return  <a style={{display:"inline"}} href={ `https://cryptscam.com/en/detail/${address}`}>Cryptscam</a>
    
            case "SophosLab":
                return  <a href={ `https://github.com/sophoslabs/IoCs/blob/master/bitcoin-addys`}>SophosLab</a>


            case "ChainAbuse":
                return  <a href={ `https://www.chainabuse.com/address/${address}`}>ChainAbuse</a>


            case "Tessi":
                return  <a href={ `https://raw.githubusercontent.com/tessii/bitcoin_ransomware_addresses/main/df_ransomware.csv`}>Tessi</a>


            case "0xjxd":
                return  <a href={ `https://github.com/0xjxd/Conti-Leak/blob/main/BTC-Wallets_Balance_Received.csv`}>0xjxd</a>

            case "KillingTheBear":
                return  <a href={ `https://raw.githubusercontent.com/Ud0g-Py/Killing-the-Bear-BloodCoin/master/attack_wallets.json`}>KillingTheBear</a>

            
            case "Ransomlook":
                return  <a href={ `https://www.ransomlook.io/crypto`}>Ransomlook</a>


            case "SpamReports":
                return  <a href={ `https://raw.githubusercontent.com/Ud0g-Py/Killing-the-Bear-BloodCoin/master/attack_wallets.json`}>SpamReports</a>
             
            
            
            // posibilities 
            // - bitcoinabuse
            // - scamsearch
            // - open
            default:
                return source
        }
    }
    const getSources = () => {
        if(sources && sources.length > 0){
            return (
            <div style={{marginLeft:"20px"}}>
                <ul>
                {Object.entries(sources).map(([key, value], index) => (
                <p key={index}>
                    <li>{getSourceLink(value)}</li>
                </p>
                ))}
                </ul>
            </div>
            );
        }else{
            return;
        }
    };

  return (
    <div>
      
 
        <div style={{ 
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',}}>
            <Popup open={isOpen} style={{backgroundColor:"white", 
            zIndex: '9999'}}>
                <div className="popup" style={{backgroundColor:"white", width:"900px", borderRadius:"10px", padding:"50px"}}>
                    <h5 style={{display: "inline"}} ><b>Address:</b><p style={{display: "inline",marginLeft:"10px", color: "gray"}}>{address}</p></h5>
                    
                    <p style={{marginTop:"10px"}}>Explore the address using blockchain <a href={`https://www.blockchain.com/explorer/addresses/btc/${address}`} >here</a></p>
                    
                    <h6 style={{marginTop:"50px"}}> Repored by:</h6>
                    { getSources()}

                    <Button variant="secondary" onClick={()=>{closePopup() }}>Close</Button>
                </div>
            </Popup>
        </div>
      
    </div>
  );
}
export default AddressPopup;