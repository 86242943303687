import React, { Component } from "react";

// We import bootstrap to make our application look better.
import "bootstrap/dist/css/bootstrap.css";

import { Container, Col, Row, Table } from 'react-bootstrap'

import ics_logo from '../../ics-logo.png'

import logo from "../../logo.svg";

import configData from '../../configs/ip.json'

import zoomPlugin from 'chartjs-plugin-zoom';

import ChartDataLabels from 'chartjs-plugin-datalabels';


import {Bar, Line} from 'react-chartjs-2'

import {
    Chart,
    ArcElement,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js'

let Options;

export default class DeFiCrime extends Component {



    async componentDidMount() {


        const headers = {
            "period":"anual",
            "type" :"All"
        };

        const rawStatisticsData = await fetch(`${configData.clientIP}:${configData.serverPort}/statistics/bitcoin/getStatistics/`, { headers })
        const statisticsData = await rawStatisticsData.json()

        const rawStatisticsLabelsData = await fetch(`${configData.clientIP}:${configData.serverPort}/statistics/bitcoin/getLabels/`, { headers })
        const statisticsLabelsData = await rawStatisticsLabelsData.json()

        const statisticsDataTotalRecv = statisticsData["Total"]["recv"]

        let data;
        if(Math.max(...statisticsDataTotalRecv) > 1000000)
            data = statisticsDataTotalRecv.map(number => (number / 1000000).toFixed(2));
        else{
            data = statisticsDataTotalRecv.map(number => (number / 1000).toFixed(2));
            this.setState({shownAs : 'Millions'});
        }
        
        this.setState({
            data: {
                labels: statisticsLabelsData,
                datasets: [{
                    label: ' Decentralized Crime  in anual base',

                    data: data,//this.state.transaction_balance,

                    fill: true,
                    
                    borderColor: 'rgb(4,54,74)',
                    backgroundColor: 'rgba(4,54,74, 0.9)',
                    tension: 0.1,
                
                            
                           
                    // stepped: true,
                }]
            },
                                  
            
        })


    }
    constructor(props) {
        super(props)
        
        Chart.register(
            ArcElement,
            CategoryScale,
            LinearScale,
            PointElement,
            LineElement,
            Title,
            Tooltip,
            Legend,
            Filler,
            zoomPlugin,
            
            ChartDataLabels
        );
        this.state = {
            statisticsData:{},
            dataLabels:[],
            dataData:[],
            data: {
                labels: [],
                datasets: [{
                    label: ' Bitcoin ',

                    data: [],

                    fill: true,
                    
                    borderColor: 'rgb(102, 240, 210)',
                    backgroundColor: 'rgba(102, 240, 210, 0.6)',
                    tension: 0.1,


                    // stepped: true,
                }]
            },
            shownAs: 'Millions',
        }
    }


    render() {

        Options = {
            responsive: true,
            plugins: {
                datalabels: {
                    anchor: 'end',
                    align: 'end',
                    formatter: (value, context) => value, // Display the actual value
                } ,
                legend: {
                    display: false // set position of legend labels
                },
            },
            scales: {
                x: {
                    display: true,
                    title: {
                        display: true
                    }
                },
                y: {
                    display: true,
                    type: 'logarithmic',
                    title: {
                        display: true,
                        text: `${this.state.shownAs} of Euros €`
                    },
                    min: 0,
                }
            },
            pan:{
                enabled: true,
                mode:"xy",
                speed:10
            },
        }

        return (
            <div style={{ backgroundColor: "white" ,  padding:"10px", paddingTop:'5%', borderRadius:"10px"}}>
                <h3 style ={{marginLeft:"30px"}}>Annual summary</h3>
                <Line options={Options} width={500} height={170} data={this.state.data} plugins={ChartDataLabels}/>
                <p style ={{textAlign:"center", fontSize : "0.9em"}}><i>Total amount of Euros Received associated with wallets considered to be crime related, shown per year spanning from 2017 until today.</i></p>
            </div>
        )
    }
}