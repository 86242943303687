
import React, { Component } from "react";
import '../../App.css';

import {Row, Col, Container} from 'react-bootstrap'

export default class DataStatistics extends Component {
    constructor(props) {
        super(props)

        this.state = {}

    }


    render() {
        return (

            <div>
                <Container>

                    <Row style={{   backgroundColor: "white", margin: "2px", padding: "20px",  borderRadius: "10px" }}>
                        <h1>kaka</h1>
                    </Row>

                </Container>
            </div>
        )
    }



}