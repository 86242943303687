import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import axios from 'axios';
import configData from '../../configs/ip.json';

export default class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      query: '',
      sources: [],
      summary: {},
      suggestions: [] // Add this state to hold suggestions
    };
  }

  isBitcoinAddress(address) {
    // Regular expression pattern for a Bitcoin address
    const bitcoinAddressPattern = /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,59}$/;
    return bitcoinAddressPattern.test(address);
  }

  handleInputChange = async (e) => {
    const query = e.target.value;
    this.setState({ query });

    try {
      // const response = await axios.get(`${configData.clientIP}:${configData.serverPort}/search?prefix=${query}`);

      const headers = { 'prefix': query };
      const response = await axios.get(`${configData.clientIP}:${configData.serverPort}/address/suggest`, { headers });
      if (response.status === 200) {
        console.log(response.data.count)
        const wallets = response.data.wallets;
        if (wallets) {
          this.setState({ suggestions: wallets });
        } else {

          this.setState({ suggestions: [] });
        }

      }
    } catch (error) {
      console.error(error);
      this.setState({ suggestions: [] });
    }
  };

  handleSuggestionClick = (wallet) => {
    window.location.href = `/address?addr=${wallet}`;
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (!this.isBitcoinAddress(this.state.query)) {
      alert("Not Valid Bitcoin Address");
    } else {
      try {
        const headers = { 'addr': this.state.query };
        const response = await axios.get(`${configData.clientIP}:${configData.serverPort}/address/info`, { headers });


        this.setState({ sources: response.data.sources, summary: response.data.summary });

        if (response.data.sources !== undefined) {
          window.location.href = `/address?addr=${this.state.query}`;
        } else {
          alert("This Address does not exist in our database");
        }
      } catch (error) {
        console.error(error);
        alert("This Address does not exist in our database");
      }
    }
  };

  async componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const type = urlParams.get('type');
    const address = urlParams.get('addr');
    this.setState({ query: address, type: type });
  }

  render() {
    const { type, query, suggestions } = this.state;

    return (
      <div style={{ textAlign: "center" }}>
        <div>
          <form onSubmit={this.handleSubmit}>
            <br />
            <input
              style={{
                borderRadius: "10px",
                border: "2px solid black",
                paddingLeft: "10px",
                height: "50px",
                width: window.innerWidth <= 600 ? "100%" : "500px",
                backgroundColor: '#EEEEEE',
                placeholder: { color: "black" },
                color: "black",
                stroke: "1px"
              }}
              type="text"
              id="searchQuery"
              name="q"
              placeholder="Enter wallet address to search"
              value={query}
              onChange={this.handleInputChange}
              required
               autoComplete="off"
            />
          </form>
        </div>
        {suggestions.length > 0 && (
          <div style={{
            position: "absolute",
            width: window.innerWidth <= 600 ? "100%" : "600px", top: "160px", left: "50%", transform: "translateX(-50%)", backgroundColor: "white", borderRadius: "10px", border: "1px solid #ccc", overflow: "hidden"
          }}>
            <div style={{ listStyleType: "none", padding: "20px", margin: "0", maxHeight: "400px", overflowY: "auto" }}>
              {suggestions.map((wallet, index) => (
                <div
                  key={index}
                  style={{ cursor: "pointer", textAlign: "left", padding: "10px", position: "relative" }}
                  onClick={() => this.handleSuggestionClick(wallet)}
                >
                  <span style={{ color: "black" }}>{query}</span>
                  <span style={{ color: "#ccc" }}>{wallet.slice(query.length)}</span>
                  <div style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%", backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "1", display: query === wallet ? "block" : "none" }} />
                </div>
              ))}
            </div>
          </div>
        )}

        <br />
        <a href={`/statistics?type=All`} style={{ textDecoration: 'none', color: type === "All" ? "black" : "gray", fontWeight: type === "All" ? "bold" : "normal" }}>All</a>
        <a href={`/statistics?type=Ransomware`} style={{ textDecoration: 'none', color: type === "Ransomware" ? "black" : "gray", fontWeight: type === "Ransomware" ? "bold" : "normal", marginLeft: "20px" }}>Ransomware </a>
        <a href={`/statistics?type=BlackmailScam`} style={{ textDecoration: 'none', color: type === "BlackmailScam" ? "black" : "gray", fontWeight: type === "BlackmailScam" ? "bold" : "normal", marginLeft: "20px" }}>BlackmailScam </a>
        <a href={`/statistics?type=BitcoinTumbler`} style={{ textDecoration: 'none', color: type === "BitcoinTumbler" ? "black" : "gray", fontWeight: type === "BitcoinTumbler" ? "bold" : "normal", marginLeft: "20px" }}>BitcoinTumbler </a>
        <a href={`/statistics?type=DarknetMarket`} style={{ textDecoration: 'none', color: type === "DarknetMarket" ? "black" : "gray", fontWeight: type === "DarknetMarket" ? "bold" : "normal", marginLeft: "20px" }}>Darknet Market </a>
        <a href={`/statistics?type=Sanctions`} style={{ textDecoration: 'none', color: type === "Sanctions" ? "black" : "gray", fontWeight: type === "Sanctions" ? "bold" : "normal", marginLeft: "20px" }}>Sanctions</a>
        <a href={`/statistics?type=Other`} style={{ textDecoration: 'none', color: type === "Other" ? "black" : "gray", fontWeight: type === "Other" ? "bold" : "normal", marginLeft: "20px" }}>Other</a>
        <br />
        <br />
      </div>
    );
  }
}
