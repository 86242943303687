
function ConvertUnixTimestampToTime(unix_timestamp) {

    let date = new Date(unix_timestamp * 1000);

    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    let hours = date.getHours();
    let minutes = date.getMinutes();
    // let seconds = date.getSeconds();

    let str = day + "/" + month + "/" + year + " " + hours + ":" + minutes
    return str;
  }
export default ConvertUnixTimestampToTime;