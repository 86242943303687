
import React, { Component } from "react";

import '../../App.css';


import { Table} from 'react-bootstrap'
  
import configData from "../../configs/ip.json"
import modifyMoneyValues from "../../utils/ModifyMoneyValues";


export default class SummaryTable extends Component {
    
    async componentDidMount(){
        
        const response = await fetch(`${configData.clientIP}:${configData.serverPort}/statistics/bitcoin/getSummary`)
        let data = await response.json();
        
        
        this.setState({data: data})
    }

    constructor(props) {
        super(props)


        this.state = {
            
            data : { }  
        }

    
    }

    getTableValues(){
    
        let data = this.state.data;
        let sorted = Object.entries(data).sort((a, b) => a[1] < b[1] ? 1 : -1);
        let table = [];
        let other;
        for(let i in sorted){
            if(sorted[i][0] === 'Other'){
                other = (<tr key={sorted[i][0]}>
                    <td>{sorted[i][0]}</td>
                    <td style={{textAlign:"right"}}>{ modifyMoneyValues(sorted[i][1])}</td>
                    {/* <td> <CurrencyFormat value={data[key]} displayType={'text'} thousandSeparator={true} renderText={value => <div>{value}</div>} /></td> */}
    
                </tr>);
                continue
            }
            const tr = (<tr key={sorted[i][0]}>
                <td>{sorted[i][0]}</td>
                <td style={{textAlign:"right"}}>{ modifyMoneyValues(sorted[i][1])}</td>
                {/* <td> <CurrencyFormat value={data[key]} displayType={'text'} thousandSeparator={true} renderText={value => <div>{value}</div>} /></td> */}

            </tr>);
            table.push(tr);
        }
        table.push(other)
        return (
          <tbody style={{textAlign:'left'}}>        
            {table}
          </tbody>
         );
    }
    render() {
        return (
        <div>
            <Table striped bordered hover >
                <thead>
                    <tr>
                        <th>Abuse Type</th>
                        <th>Stollen Funds in Euros €</th>
                    </tr>
                </thead>

                {this.getTableValues()}
            </Table>
        </div>
        )
    }
}