// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.column {
    float: left;
    width: 33.33%;
    margin-top:'150px';
    background-color: 'white';
}

.row:after {
    content: "";
    display: table;
    clear: both;
}

.line{
    width: 112px;
    height: 47px;
    border-bottom: 1px solid black;
    position: absolute;
    }

.section{
    text-align: "center";
    margin-top:'150px';
    background-color: 'white'; 
    border-style: 'double';
}`, "",{"version":3,"sources":["webpack://./src/footer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,cAAc;IACd,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,8BAA8B;IAC9B,kBAAkB;IAClB;;AAEJ;IACI,oBAAoB;IACpB,kBAAkB;IAClB,yBAAyB;IACzB,sBAAsB;AAC1B","sourcesContent":[".column {\n    float: left;\n    width: 33.33%;\n    margin-top:'150px';\n    background-color: 'white';\n}\n\n.row:after {\n    content: \"\";\n    display: table;\n    clear: both;\n}\n\n.line{\n    width: 112px;\n    height: 47px;\n    border-bottom: 1px solid black;\n    position: absolute;\n    }\n\n.section{\n    text-align: \"center\";\n    margin-top:'150px';\n    background-color: 'white'; \n    border-style: 'double';\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
